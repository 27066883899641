import * as React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

import { Container } from "../../components/global"
import Layout from "../../components/common/layout/layout"
import SEO from "../../components/common/layout/seo"
import Navigation from "../../components/common/navigation/navigation"
import Footer from "../../components/sections/footer"

const BlogPost = ({ data }) => {
  const title = data.mdx.frontmatter.title
  return (
    <Layout pageTitle={title}>
      <SEO title={title} />
      <Navigation />
      <StyledContainer>
        <HeaderWrapper>
          <Title>{title}</Title>
          <p>{data.mdx.frontmatter.date}</p>
          <MDXRenderer>{data.mdx.body}</MDXRenderer>
        </HeaderWrapper>
      </StyledContainer>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String) {
    mdx(slug: { eq: $slug }) {
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`

export default BlogPost

const StyledContainer = styled(Container)``

const HeaderWrapper = styled.header`
  padding: 160px 0 80px 0;
  position: relative;
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
`
const Title = styled.h5`
  font-size: 16px;
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 16px;
`
